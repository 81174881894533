a.logo {
	float: left;
}

a.logo-mobile {
	@media(min-width: $screen-sm) {
		display: none;
	}
}

.home, .inscription {
	h1 {
		margin-bottom: 60px;
	}
	
	h2 {
		color: $orange;
		position: relative;
		
		&:before {
			position: absolute;
			left: -90px;
			padding-right: 10px;
			content: url('../../img/loupe.png');
		}
	}
	
	.encart {
		padding-left: 100px;
	}
	
	
}

.panel-height {
	height: 250px;
	position: relative;
	
	.container-btn {
		position: absolute;
		bottom: 15px;
		left: 0;
		right: 0;
		text-align: center;
	}
}

.compteur {
	span {
		background: $orange;
		color: #fff;
		padding: 0 10px;
		position: relative;
		
		&:before {
			position: absolute;
			display: block;
			top: 0;
			left: -60px;
			width: 50px;
			height: 100%;
			background: $orange;
			color: #fff;
			content: "";
			font-family: "FontAwesome";
			text-align: center;
			line-height: 55px;
			font-size: 60px;
			overflow: hidden;
		}
	}
	
	color: $orange;
	font-size: 38px;
	font-weight: 900;
	text-transform: uppercase;
}