html, body {
	min-height: 100%;
}

.panel-heading {
	border-radius: 0;
}

.job-navbar {
	@media(min-width: 768px) {
		padding-top: 45px;
	}
}

.login-navbar {
	@media(min-width: 768px) {
		padding-top: 45px;
	}
}

#app-navbar-collapse {
	position: relative;
	
	.baseline {
		position: absolute;
		top: 5px;
		right: 0;
		color: #fff;
		font-weight: bold;
		padding-left: 120px;
		font-size: 16px;
		
		@media(max-width: $screen-sm) {
			display: none;
		}
	}
}

#home-slide {
	margin-top: -20px;
	margin-bottom: 40px;
	
	
	position: relative;
	
	.item {
		padding-bottom: 21.32%;
		background-size: cover;

		@media(max-width: $screen-sm) {
			padding-bottom: 50%; 
		}

	}
	
	.text-slide {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		right: 0;
		padding: 15px 0;
		background: rgba(255,255,255,0.8);
		text-align: center;
		font-size: 22px;
		font-weight: bold;
		color: #666;
		z-index: 10;
	}
}

.list-jobinco {
	padding-left: 30px;
	list-style: none;
	
	li {
		position: relative;
		margin-bottom: 10px;
		
		&:before {
			position: absolute;
			display: block;
			left: -30px;
			width: 16px;
			height: 16px;
			background: $orange;
			color: #fff;
			content: "";
			font-family: "FontAwesome";
			text-align: center;
			line-height: 20px;
			font-size: 24px;
			overflow: hidden;
		}
	}
}


