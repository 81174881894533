
.offre {
	
	position: relative;
	/*padding-right: 200px;
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: solid 1px #000;*/
	overflow: auto;
	
	.logo {
		max-width: 200px;
		/*position: absolute;
		right: 0;
		top: 0;*/
		float: right;
	}
	
	.description {
		
	}
	
}

.fiche-annonce {
	
	.img-entreprise {
		margin: 0 auto;
		margin-bottom: 15px;
	}
	
}


.search-title {
	font-size: 18px;
	border-bottom: 1px dotted $orange;
}

			div.select-style {
		    padding: 0;
		    margin: 0;
		    width: 100%;
		    overflow: hidden;
		    background-color: #FFF;
			height: 36px;
			//padding-top: 15px;
			box-sizing: border-box;
		    background: transparent;
			//border-top: $input-border 1px solid;
			border: $text-color 1px solid;
			//border-left: $input-border 1px solid;
			//margin-bottom: 15px;
			position: relative;
				
				&:before {
					content: "";
					font-family: "FontAwesome";
					color: #fff;
					position: absolute;
					right: 15px;
					top: 50%;
					padding: 0 3px;
					background: $orange;
					transform: translateY(-50%);
				}
			}
			
			div.select-style select {
		    padding: 5px 8px;
		    width: 100%;
		    color: $text-color;
		    border: none;
		    box-shadow: none;
		    box-sizing: border-box;
		    background-color: transparent;
		    background-image: none;
		    -webkit-appearance: none;
		       -moz-appearance: none;
		            appearance: none;
			//font-size: 18px;
			}
			
			div.select-style select:focus {
			    outline: none;
			}	

.highlight {
	
	div.select-style {
		border-color: $orange;
	}
	
	label {
		color: $orange;
		text-transform: uppercase;
		font-weight: 900;
		font-size: 16px;
	}
}


.title-offres {
	font-weight: 900;
	font-size: 30px;
}

.sub-title-offres {
	font-size: 30px;
	color: $orange;
	border-bottom: 1px dotted $orange;
	position: relative;
	margin-bottom: 30px;
		
	@media(min-width: $screen-md) {
		&:before {
			content: url('../../img/loupe.png');
			position: absolute;
			left: -80px;
		}
	}
}