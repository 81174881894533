// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


$orange: #F28922;
$brand-primary:         $orange; // #337ab7


$headings-color: #A9A9A9;

$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;


//NAVBAR
$navbar-default-color:             #fff;
$navbar-default-bg:                $orange;
$navbar-default-border:            $orange;

// Navbar links
$navbar-default-link-color:                #fff;
$navbar-default-link-hover-color:          #EEE;
$navbar-default-link-hover-bg:             lighten($orange,10%);
$navbar-default-link-active-color:         #EEE;
$navbar-default-link-active-bg:            lighten($orange,10%);
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

$text-color:	#808081;

$font-family-sans-serif: 'Lato', sans-serif;