.btn {
	padding: 0 30px;
}

.btn-primary {
	
	position: relative;
	//padding-left: 30px;
	
	&:before {
			position: absolute;
			left: -5px;
			font-size: 36px;
			line-height: 28px;
			color: #fff;
			content: "";
			font-family: "FontAwesome";
	}
}