footer {
	padding: 30px 0;
	background: #000;
	margin-top: 50px;
	
	p.title {
		color: rgba(146,146,146,1);
		text-transform: uppercase;
		text-decoration: underline;
		font-size: 18px;
	}
	
	p.texte {
		color: #B3BBC0;
	}
	
	.bottom {
		padding-top: 30px;
	}
}

